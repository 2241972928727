import React from 'react'
import Navbar from 'src/components/layout/Navbar'
import Footer from 'src/components/layout/Footer'
import Meta from 'src/components/layout/Meta'
import TopBanner from 'src/components/layout/TopBanner'

export default function MarketingLayout(props) {
    const { pageContext, children, noFooter, title, id, showTopBanner, topBanner } = props;
    const { route } = pageContext;
    const param = id || '';

    return (
        <>
            <Meta title={title} />
            {showTopBanner && <TopBanner>{topBanner}</TopBanner>}
            <Navbar route={route} param={param}/>
            <div className="pt-16 lg:pt-24 h-inner">
                {children}
            </div>
            {!noFooter && <Footer />}
        </>
    )
}

