import React, {useContext} from 'react'
import { Context as ThemeContext } from 'src/context/ThemeContext'

export default function Close(props) {
  const { height, width, color } = props;
  const { state: { theme } } = useContext(ThemeContext)
  const fill = theme === 'inverse' ? "#23315F" : '#fff'

  return (
    <svg width={width || 26} height={height || 26} viewBox="0 0 26 26" {...props} cursor="pointer">
      <title>{"close"}</title>
      <path
        d="M24.845 1.155c.608.607.648 1.567.122 2.222l-.122.135L15.357 13l9.488 9.488a1.667 1.667 0 01-2.222 2.479l-.135-.122L13 15.357l-9.488 9.488a1.667 1.667 0 01-2.479-2.222l.122-.135L10.643 13 1.155 3.512a1.667 1.667 0 012.222-2.479l.135.122L13 10.643l9.488-9.488c.651-.651 1.706-.651 2.357 0z"
        fill={color || fill}
        fillRule="nonzero"
      />
    </svg>
  )
}
