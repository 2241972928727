import React, { useContext, useState } from 'react'
import Modal from 'src/components/modal/Modal'
import useForm from 'src/hooks/useForm'
import { useVisitorApi } from 'src/hooks/useApi'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import Input from 'src/components/forms/Input'
import { SubmitButton } from 'src/components/buttons/Primary'
import { useTranslation } from 'react-i18next'
import {Error} from 'src/components/forms'
import { useLocation } from '@reach/router';
import useLocalizedRoutes from "../../hooks/useLocalizedRoutes";

export default function ForgotPasswordModal({ active, onClickClose }) {
    const { t } = useTranslation();
    const { forgotPassword } = useVisitorApi();
    const [ success, setSuccess ] = useState(false);
    const { toggleForgotPassword } = useContext(ProfileContext);
    const location = useLocation();
    const { resetPassword } = useLocalizedRoutes();
    const initialValues = {
        email: '',
    };
    const {
        values,
        onChangeValue,
        submit,
        isLoading,
        errors,
    } = useForm({
        initialValues,
        onSubmit,
        onSuccess: onRegistrationCompleted,
        validationMessages: t('student.register.globalError'),
    });

    const COL_FULL = 'px-4 w-full mt-6';

    async function onSubmit(values) {
        return await forgotPassword({
            ...values,
            redirect_url: `${location.origin}${resetPassword}`
        });
    }

    function onRegistrationCompleted() {
        setSuccess(true);
    }

    return (
        <Modal active={active} onClickClose={onClickClose} className="lg:max-w-lg overflow-scroll">
            {success ? (
                <>
                    <h3 className="font-titles font-bold text-2xl lg:text-3xl">{t('general.forgotPassword.success.title')}</h3>
                    <p className="mt-4 mb-8 text-base text-grey-800">{t('general.forgotPassword.success.body')}</p>
                    <a onClick={() => setSuccess(false)} className="text-dark-blue-600 text-lg font-semibold underline cursor-pointer">{t('general.forgotPassword.success.cta')}</a>
                </>
            ) : (
            <form
                onSubmit={submit}
            >
                <h3 className="font-titles font-bold text-2xl lg:text-3xl">{t('general.forgotPassword.title')}</h3>
                <p className="mt-4 text-base text-grey-800">{t('general.forgotPassword.intro')}</p>
                <div className="flex flex-wrap items-start -mx-4">
                    <div className={COL_FULL}>
                        <Input
                            isRequired
                            name="email"
                            value={values.email}
                            error={errors.email}
                            label={t('general.forms.email')}
                            onChange={onChangeValue}
                        />
                    </div>
                    <div className={COL_FULL}>
                        <SubmitButton
                            className="w-full"
                            isLoading={isLoading}
                        >
                            {t('general.continue')}
                        </SubmitButton>
                    </div>
                    {errors.general &&
                    <div className={COL_FULL}>
                        <Error>{errors.general}</Error>
                    </div>
                    }
                </div>
            </form>
            )}
        </Modal>
    )
}
