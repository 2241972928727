import React, { useContext } from 'react'
import { Context as ThemeContext } from 'src/context/ThemeContext'

export default function SvgComponent(props) {
  const { state: { theme } } = useContext(ThemeContext)
  const fill = theme === 'inverse' ? 'rgba(36, 48, 94, 1)' : '#fff'

  return (
    <svg width={22} height={17} viewBox="0 0 22 17" {...props} cursor="pointer">
      <title>{"Hamburger"}</title>
      <path
        d="M20 14.25a1.25 1.25 0 01.128 2.494L20 16.75H2a1.25 1.25 0 01-.128-2.494L2 14.25h18zm0-7a1.25 1.25 0 01.128 2.494L20 9.75H8.5a1.25 1.25 0 01-.128-2.494L8.5 7.25H20zm0-7a1.25 1.25 0 01.128 2.494L20 2.75H2A1.25 1.25 0 011.872.256L2 .25h18z"
        fillRule="nonzero"
        fill={fill}
      />
    </svg>
  )
}
