import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import PrimaryButton, { TransparentButton, LightButton } from 'src/components/buttons/Primary'

export default function NavNotLoggedIn({ onClickLogin }){
    const { state: { theme } } = useContext(ThemeContext)
    const { register } = useLocalizedRoutes();
    const { t } = useTranslation();

    function conditionalRender() {
        if(theme === 'inverse') {
            return (
                <div className="hidden lg:inline-block">
                    <TransparentButton onClick={onClickLogin} className="text-dark-blue-600">{t('general.signIn')}</TransparentButton>
                    <PrimaryButton onClick={() => navigate(register)}>{t('general.register')}</PrimaryButton>
                </div>
            )
        }

        return (
            <div className="hidden lg:inline-block">
                <TransparentButton onClick={onClickLogin} className="text-white">{t('general.signIn')}</TransparentButton>
                <LightButton onClick={() => navigate(register)} >{t('general.register')}</LightButton>
            </div>
        )
    }

    return (
        <div className="flex justify-between items-center mx-4">
            {conditionalRender()}
        </div>
    )
}
