import React, {useState, useContext} from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import LanguageSelection from 'src/components/layout/LanguageSelection'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import Navigation from "src/components/layout/Navigation"
import NavLoggedIn from 'src/components/interactivity/NavLoggedIn'
import NavNotLoggedIn from 'src/components/interactivity/NavNotLoggedIn'
import LoginModal from 'src/components/modal/LoginModal'
import ForgotPasswordModal from 'src/components/modal/ForgotPasswordModal'
import ResetPasswordModal from 'src/components/modal/ResetPasswordModal'
import Logo from 'src/components/icons/Logo'
import MenuIcon from 'src/components/icons/Menu'

const DEFAULT ='fixed top-0 inset-x-0 m-0 w-full py-2 lg:py-6 px-4 flex justify-center z-50 h-16 lg:h-24';
const NORMAL = 'bg-baby-blue-600';
const INVERSE = 'bg-white border-b border-grey-300';

const Navbar = ({route, param}) => {
    const {state: { profile, showLogin, showForgotPassword, showResetPassword }, toggleLogin, toggleForgotPassword, toggleResetPassword } = useContext(ProfileContext);
    const {state: { theme }} = useContext(ThemeContext);
    const [isOpen, setIsOpen] = useState(false);
    const {home} = useLocalizedRoutes();

    const conditionalClasses = {
        [NORMAL]: theme !== 'inverse',
        [INVERSE]: theme === 'inverse'
    };

    function conditionalNavItem() {
        if (!profile) return <NavNotLoggedIn onClickLogin={toggleLogin}/>

        return <NavLoggedIn profile={profile}/>
    }

    function toggle() {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <div className={classnames(DEFAULT, conditionalClasses)}>
                <div className="container mx-auto flex justify-between items-center">
                    <div className="flex justify-between w-full items-center">
                        <Link to={home} className="inline-block w-32">
                            <Logo className="lg:w-auto" />
                        </Link>
                        <div className="flex items-center">
                            {conditionalNavItem()}
                            <LanguageSelection route={route} param={param}/>
                        </div>
                    </div>
                    <div className="mt-1">
                        <MenuIcon onClick={toggle} />
                    </div>
                </div>
            </div>
            {isOpen && <Navigation onClickClose={toggle} />}
            {showLogin && <LoginModal active={showLogin} onClickClose={toggleLogin} />}
            {showForgotPassword && <ForgotPasswordModal active={showForgotPassword} onClickClose={toggleForgotPassword} />}
            {showResetPassword && <ResetPasswordModal active={showResetPassword} onClickClose={toggleResetPassword} />}
        </>
    )
}

export default Navbar
