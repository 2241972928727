import React from 'react'
import classNames from 'classnames'
import CloseCircle from 'src/components/icons/CloseCircle';

const DEFAULT = classNames(
    'max-h-screen w-auto z-70 bg-white px-6 py-12 lg:p-12 relative',
    'animation-fade-in-from-bottom animation-450ms animation-once animation-fill-forwards opacity-0'
);

export default function Modal({ active, children, onClickClose, className }) {
    return (
        <>
            {active &&
                <div className="fixed inset-0 flex justify-center items-end lg:items-center z-60 animation-fade-in animation-450ms animation-once animation-fill-forwards opacity-0">
                    <div className={classNames(DEFAULT, className)}>
                        <button className="absolute right-0 top-0 mr-6 mt-6 outline-none focus:outline-none" onClick={onClickClose}>
                            <CloseCircle />
                        </button>
                        {children}
                    </div>
                    <div className="fixed z-60 inset-0 bg-grey-backdrop" onClick={onClickClose} />
                </div>
            }
        </>
    )
}

Modal.defaultProps = {
    visible: false
};
