import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import Logo from 'src/components/icons/Logo'
import FacebookIcon from 'src/components/icons/Facebook'
import InstagramIcon from 'src/components/icons/Instagram'

export default function Footer(){
    const { t, i18n } = useTranslation();
    const { howItWorks, findTeacher, faqs, privacyPolicy } = useLocalizedRoutes();

    const baseUrl = process.env.API_BASE_URL;

    return (
        <div className="border-t border-grey-300">
            <div className="px-4">
                <div className="container mx-auto my-8 flex justify-between">
                    <Logo color="rgba(36, 48, 94, 1)"/>
                    <div className="hidden lg:flex justify-between w-1/4">
                        <Link to={howItWorks}><span>{t('general.nav.howItWorks')}</span></Link>
                        <Link to={findTeacher}><span>{t('general.nav.findATeacher')}</span></Link>
                        <Link to={faqs}><span>{t('general.nav.faq')}</span></Link>
                    </div>
                    <div className="flex w-16 justify-between items-center">
                        <a href="https://www.facebook.com/Whambam-104585001312438" target="_blank" rel="noreferrer"><FacebookIcon /></a>
                        <a href="https://www.instagram.com/whambam_be/" target="_blank" rel="noreferrer"><InstagramIcon/></a>
                    </div>
                </div>
            </div>
            <div className="px-4 mt-4 bg-grey-100 py-6 w-full flex justify-between">
                <div className="container mx-auto flex justify-between flex-wrap">
                    <div className="w-full md:w-auto text-center md:text-left order-2 md:order-1">
                        <span className="text-xs">Made with ❤ by </span>
                        <a href="https://pointbreak.co"
                           target="_blank"
                           rel="noreferrer noopener"
                           className="text-xs font-semibold">
                            Pointbreak
                        </a>
                    </div>
                    <div className="w-full md:w-auto text-center md:text-right order-1 md:order-2">
                        <a href={`${baseUrl}/Whambam-Terms&Conditions-${i18n.language}.pdf`} className="text-xs">{t('general.nav.terms')}</a>
                        <span className="text-xs px-4">&bull;</span>
                        <a href={privacyPolicy} target="_blank" className="text-xs">{t('general.nav.privacy')}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
