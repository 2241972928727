import React from 'react'

export default function Instagram(props) {
    return (
        <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
            <title>{"social/instagram"}</title>
            <path
                d="M11 1.982c2.937 0 3.285.011 4.445.064 1.072.05 1.655.228 2.042.379.514.2.88.438 1.265.823.385.385.624.751.823 1.265.15.387.33.97.379 2.042.053 1.16.064 1.508.064 4.445 0 2.937-.011 3.285-.064 4.445-.05 1.072-.228 1.655-.379 2.043-.2.513-.438.88-.823 1.264a3.408 3.408 0 01-1.265.823c-.387.15-.97.33-2.042.379-1.16.053-1.508.064-4.445.064-2.937 0-3.285-.011-4.445-.064-1.072-.049-1.655-.228-2.042-.379-.514-.2-.88-.438-1.265-.823a3.408 3.408 0 01-.823-1.264c-.15-.388-.33-.97-.379-2.043-.053-1.16-.064-1.508-.064-4.445 0-2.937.011-3.285.064-4.445.049-1.072.228-1.655.379-2.042.2-.514.438-.88.823-1.265a3.408 3.408 0 011.265-.823c.387-.15.97-.33 2.042-.379 1.16-.053 1.508-.064 4.445-.064M12.096.001c2.01.003 2.428.019 3.44.065 1.17.054 1.97.24 2.67.512a5.392 5.392 0 011.948 1.268 5.392 5.392 0 011.268 1.949c.272.7.458 1.499.512 2.67C21.987 7.638 22 8.013 22 11s-.013 3.362-.066 4.535c-.054 1.171-.24 1.97-.512 2.67a5.392 5.392 0 01-1.268 1.949 5.392 5.392 0 01-1.949 1.269c-.7.271-1.499.457-2.67.51-1.173.054-1.548.067-4.535.067s-3.362-.013-4.535-.066c-1.171-.054-1.97-.24-2.67-.511a5.392 5.392 0 01-1.949-1.27 5.392 5.392 0 01-1.269-1.948c-.271-.7-.457-1.499-.51-2.67C.02 14.524.003 14.106 0 12.096V9.904c.003-2.01.019-2.428.065-3.44.054-1.17.24-1.97.511-2.67a5.392 5.392 0 011.27-1.948A5.392 5.392 0 013.794.578c.7-.272 1.499-.458 2.67-.512C7.476.02 7.894.004 9.904.001zm-1.074 5.368a5.653 5.653 0 100 11.306 5.653 5.653 0 000-11.306zm0 1.983a3.67 3.67 0 110 7.34 3.67 3.67 0 010-7.34zm5.827-3.554a1.31 1.31 0 100 2.619 1.31 1.31 0 000-2.62z"
                fill="#242F5E"
                fillRule="evenodd"
            />
        </svg>
    )
}