import React from 'react'

export default function CloseCircle(props) {
    const { color } = props;

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props} cursor="pointer" fill={color || '#BBC2C6'}>
            <title>{"close-circle"}</title>
            <path
                d="M12 .4C18.407.4 23.6 5.593 23.6 12S18.407 23.6 12 23.6.4 18.407.4 12 5.593.4 12 .4zm0 2.4a9.2 9.2 0 100 18.4 9.2 9.2 0 000-18.4zm3.96 5.24a1.2 1.2 0 01.078 1.612l-.078.085L13.696 12l2.207 2.207a1.2 1.2 0 01-1.611 1.775l-.086-.078L12 13.696 9.737 15.96a1.2 1.2 0 01-1.775-1.612l.078-.085 2.262-2.264-2.205-2.205a1.2 1.2 0 011.611-1.775l.086.078 2.205 2.205 2.264-2.262a1.2 1.2 0 011.697 0z"
            />
        </svg>
    )
}
