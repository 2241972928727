import React, { useState } from 'react'
import Modal from 'src/components/modal/Modal'
import useForm from 'src/hooks/useForm'
import { useVisitorApi } from 'src/hooks/useApi'
import Input from 'src/components/forms/Input'
import { SubmitButton } from 'src/components/buttons/Primary'
import { useTranslation } from 'react-i18next'
import { Error } from 'src/components/forms'
import { useQueryParam, StringParam } from 'use-query-params'

export default function ResetPasswordModal({ active, onClickClose }) {
    const { t } = useTranslation();
    const { resetPassword } = useVisitorApi();
    const [ success, setSuccess ] = useState(false);
    const [resetToken] = useQueryParam("token", StringParam);
    const [email] = useQueryParam("email", StringParam);
    const initialValues = {
        password: '',
        passwordConfirmation: '',
    };
    const {
        values,
        onChangeValue,
        submit,
        isLoading,
        errors,
    } = useForm({
        initialValues,
        onSubmit,
        onFail:() => console.log('fail'),
        onSuccess: onRegistrationCompleted,
        validationMessages: t('student.register.globalError'),
    });
    const COL = 'px-4 w-1/2 mt-6';
    const COL_FULL = 'px-4 w-full mt-6';

    async function onSubmit(values) {
        return await resetPassword({
            ...values,
            email: email,
            token: resetToken
        });
    }

    function onRegistrationCompleted() {
        setSuccess(true);
    }

    return (
        <Modal active={active} onClickClose={onClickClose} className="lg:max-w-lg overflow-scroll">
            {success ? (
                <>
                    <h3 className="font-titles font-bold text-2xl lg:text-3xl">{t('general.resetPasswordModal.success.title')}</h3>
                    <p className="mt-4 mb-8 text-base text-grey-800">{t('general.resetPasswordModal.success.body')}</p>
                </>
            ) : (
            <form
                onSubmit={submit}
            >
                <h3 className="font-titles font-bold text-2xl lg:text-3xl">{t('general.resetPasswordModal.title')}</h3>
                <p className="mt-4 text-base text-grey-800">{t('general.resetPasswordModal.intro')}</p>
                <div className="flex flex-wrap items-start -mx-4">
                    <div className={COL_FULL}>
                        <Input
                            isRequired
                            type="password"
                            name="password"
                            value={values.password}
                            error={errors.password}
                            label={t('general.forms.password')}
                            onChange={onChangeValue}
                        />
                    </div>
                    <div className={COL_FULL}>
                        <Input
                            isRequired
                            type="password"
                            name="passwordConfirmation"
                            value={values.passwordConfirmation}
                            error={errors.passwordConfirmation}
                            label={t('general.forms.passwordConfirmation')}
                            onChange={onChangeValue}
                        />
                    </div>
                    <div className={COL_FULL}>
                        <SubmitButton
                            className="w-full"
                            isLoading={isLoading}
                        >
                            {t('general.resetPasswordModal.cta')}
                        </SubmitButton>
                    </div>
                    {errors.general &&
                        <div className={COL_FULL}>
                            <Error>{errors.general}</Error>
                        </div>
                    }
                </div>
            </form>
            )}
        </Modal>
    )
}
