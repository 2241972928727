import React, { useContext } from 'react'
import { Context as ThemeContext } from 'src/context/ThemeContext'

export default function User(props) {
  const { color } = props;
  const { state: { theme } } = useContext(ThemeContext);
  const fill = theme === 'inverse' ? "#23315F" : '#fff';

  return (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props} cursor="pointer">
      <title>{"profile"}</title>
      <path
        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm.115 15.85c-2.66 0-5.023 1.932-5.94 4.784A10.362 10.362 0 0012 22.415c2.24 0 4.315-.707 6.013-1.91-.95-2.79-3.284-4.656-5.898-4.656zM12 1.584C6.248 1.585 1.585 6.248 1.585 12c0 2.991 1.26 5.688 3.28 7.587 1.243-3.172 4.048-5.323 7.25-5.323 3.141 0 5.91 2.072 7.185 5.164A10.383 10.383 0 0022.415 12c0-5.752-4.663-10.415-10.415-10.415zm.011 3.51a3.962 3.962 0 110 7.924 3.962 3.962 0 010-7.925zm0 1.584a2.377 2.377 0 100 4.755 2.377 2.377 0 000-4.755z"
        fill={color ?? fill}
        fillRule="nonzero"
      />
    </svg>
  )
}
