import React, { useState } from 'react'
import Close from 'src/components/icons/Close'

export default function TopBanner(props) {
    const { children } = props;
    const [ visible, setVisible ] = useState(true);

    return (
        <>
            {visible && (
                <div className="fixed inset-x-0 top-0 bg-dark-blue-600 z-60 p-2">
                    <div className="container text-white mx-auto pr-16">
                        {children}
                    </div>
                    <button
                        type="button"
                        className="absolute right-0 top-0 mt-3 mr-8"
                        onClick={() => setVisible(false)}
                    >
                        <Close
                            width={14}
                            height={14}
                            color={'#fff'}
                        />
                    </button>
                </div>
            )}
        </>
    )
}
