import React, { useContext } from 'react'
import { Link, navigate } from 'gatsby'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import CloseIcon from 'src/components/icons/Close'
import PrimaryButton, { TransparentButton } from 'src/components/buttons/Primary'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import UserIcon from 'src/components/icons/User'

const DEFAULT = 'font-headers text-3xl lg:text-6xl font-bold text-dark-blue-600 hover:text-egg-shell-600 transition-color duration-100';
const ACTIVE = 'text-egg-shell-600 border-b-4 border-egg-shell-600';

const NAV_ITEM = 'mb-4 animation-fade-in-from-bottom animation-450ms animation-once animation-fill-forwards opacity-0 text-center lg:text-left';

export default function Navigation({ onClickClose }) {
    const { t } = useTranslation();
    const { home, findTeacher, howItWorks, faqs, register, studentAccount, teacherAccount, editStudentAccount } = useLocalizedRoutes();
    const {state: { profile }, toggleLogin, forgetProfile } = useContext(ProfileContext);

    function generateRoute() {
        if(profile.email_verified_at) {
            return profile.student_profile ? studentAccount : teacherAccount;
        } else {
            return profile.student_profile ? editStudentAccount : teacherAccount
        }
    }

    return (
        <div className="fixed inset-0 bg-baby-blue-600 z-50 px-8 lg:p-32 animation-fade-in animation-300ms animation-once animation-fill-forwards">
            <div className="container mx-auto flex flex-col justify-center items-center h-full w-full">
                <ul className="mb-12 w-full">
                    <li className={classNames(NAV_ITEM)}>
                        <Link
                            to={home}
                            className={DEFAULT}
                            activeClassName={ACTIVE}
                            onClick={onClickClose}
                        >
                            {t('general.nav.home')}
                        </Link>
                    </li>
                    <li className={classNames(NAV_ITEM, 'animation-delay-150ms')}>
                        <Link
                            to={howItWorks}
                            className={DEFAULT}
                            activeClassName={ACTIVE}
                            onClick={onClickClose}
                        >
                            {t('general.nav.howItWorks')}
                        </Link>
                    </li>
                    <li className={classNames(NAV_ITEM, 'animation-delay-300ms')}>
                        <Link
                            to={findTeacher}
                            className={DEFAULT}
                            activeClassName={ACTIVE}
                            onClick={onClickClose}
                        >
                            {t('general.nav.findATeacher')}
                        </Link>
                    </li>
                    <li className={classNames(NAV_ITEM, 'animation-delay-450ms')}>
                        <Link
                            to={faqs}
                            className={DEFAULT}
                            activeClassName={ACTIVE}
                            onClick={onClickClose}
                        >
                            {t('general.nav.faq')}
                        </Link>
                    </li>
                    <li className={classNames(NAV_ITEM, 'animation-delay-600ms hidden lg:block')}>
                        <Link
                            to={register}
                            className={DEFAULT}
                            activeClassName={ACTIVE}
                            onClick={onClickClose}
                        >
                            {t('general.nav.register')}
                        </Link>
                    </li>
                </ul>
                <div className="flex items-center flex-wrap -mx-2 w-full lg:hidden animation-fade-in-from-bottom animation-450ms animation-once animation-fill-forwards opacity-0 animation-delay-600ms">
                    {profile ? (
                        <>
                            <div className="w-full">
                                <span
                                    className="flex justify-center items-center w-full"
                                    onClick={() => {
                                        onClickClose();
                                        navigate(generateRoute());
                                    }}
                                >
                                    <UserIcon color={'#fff'} />
                                    <span className="text-white pl-2 text-xl font-semibold">{profile.first_name}</span>
                                </span>
                            </div>
                            <div className="w-full">
                                <span onClick={forgetProfile} className="mt-6 block text-center p-2 text-lg text-dark-blue-600">{t('student.profile.nav.signout')}</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="px-2 w-6/12">
                                <TransparentButton
                                    className="text-white"
                                    onClick={() => {
                                        onClickClose();
                                        toggleLogin();
                                    }}
                                >{t('general.signIn')}</TransparentButton>
                            </div>
                            <div className="px-2 w-6/12">
                                <PrimaryButton
                                    className="w-full"
                                    onClick={() => {
                                        navigate(register);
                                        onClickClose();
                                    }}
                                >{t('general.nav.register')}</PrimaryButton>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <button
                onClick={onClickClose}
                className="absolute top-0 right-0 mt-1 lg:mt-6 mr-0 lg:mr-12 p-4 focus:outline-none"
            >
                <CloseIcon />
            </button>
        </div>
    )

}


