import React from 'react'

export default function Facebook(props) {
    return (
        <svg width={11} height={23} viewBox="0 0 11 23" {...props}>
            <title>{"social/facebook"}</title>
            <path
                d="M7.315 23H2.437V11.499H0V7.536h2.437v-2.38C2.437 1.923 3.813 0 7.723 0h3.255v3.964H8.944c-1.523 0-1.623.554-1.623 1.588l-.007 1.984H11l-.431 3.963H7.314V23z"
                fill="#242F5E"
                fillRule="evenodd"
            />
        </svg>
    )
}

