import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import Modal from 'src/components/modal/Modal'
import useForm from 'src/hooks/useForm'
import { useVisitorApi } from 'src/hooks/useApi'
import { Context as ProfileContext } from 'src/context/ProfileContext'
import Input from 'src/components/forms/Input'
import { SubmitButton, OutlinedButton } from 'src/components/buttons/Primary'
import { useTranslation } from 'react-i18next'
import Checkbox from 'src/components/forms/Checkbox'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import {Error} from 'src/components/forms'

export default function LoginModal({ active, onClickClose }) {
    const { t, i18n } = useTranslation();
    const { getToken } = useVisitorApi();
    const { state: { profile }, toggleLogin, hideLogin,toggleForgotPassword } = useContext(ProfileContext);
    const [ loginSuccess, setLoginSuccess] = useState(false)
    const initialValues = {
        username: '',
        password: '',
        rememberMe: ''
    };
    const {
        values,
        setValue,
        onChangeValue,
        onChangeValueByName,
        submit,
        isLoading,
        errors,
    } = useForm({
        initialValues,
        onSubmit,
        onSuccess: onRegistrationCompleted,
        validationMessages: t('student.register.globalError'),
    });
    const COL = 'px-4 mt-6 w-full md:w-1/2';
    const COL_FULL = 'px-4 w-full mt-6';
    const { register, editStudentAccount, teacherAccount } = useLocalizedRoutes();

    async function onSubmit(values) {
        return await getToken({
            ...values
        });
    }

    useEffect(() => {
        if(profile && loginSuccess) {
            navigate(profile.student_profile ? editStudentAccount : teacherAccount)
            hideLogin();
        }
    }, [profile, loginSuccess])

    function onRegistrationCompleted() {
        setLoginSuccess(true)
    }

    function forgotPassword() {
        toggleLogin();
        toggleForgotPassword();
    }

    return (
        <Modal active={active} onClickClose={onClickClose} className="lg:max-w-lg overflow-scroll">
            <form
                onSubmit={submit}
            >
                <h3 className="font-titles font-bold text-2xl lg:text-3xl">Login</h3>
                <div className="flex flex-wrap items-start -mx-4">
                    <div className={COL_FULL}>
                        <Input
                            isRequired
                            name="username"
                            value={values.username}
                            error={errors.username}
                            label={t('general.forms.email')}
                            onChange={onChangeValue}
                        />
                    </div>
                    <div className={COL_FULL}>
                        <Input
                            isRequired
                            type="password"
                            name="password"
                            value={values.password}
                            error={errors.password}
                            label={t('general.forms.password')}
                            onChange={onChangeValue}
                        />
                    </div>
                    { errors.notAuthorized && <Error>{errors.notAuthorized}</Error>}
                    { errors.general && <Error>{errors.general}</Error>}
                    <div className={classNames(COL, 'flex justify-center md:block')}>
                        <Checkbox
                            label={t('general.forms.rememberMe')}
                            isChecked={values.rememberMe}
                            error={errors.rememberMe}
                            onChange={onChangeValueByName('rememberMe')}
                        />
                    </div>
                    <div className={classNames(COL, 'text-center md:text-right')}>
                        <a onClick={forgotPassword} className="cursor-pointer text-base text-grey-800 underline">{t('general.forgotPassword.title')}?</a>
                    </div>
                    <div className={COL_FULL}>
                        <SubmitButton
                            className="w-full"
                            isLoading={isLoading}
                        >
                            {t('general.signIn')}
                        </SubmitButton>
                    </div>
                    <div className="w-full flex items-center px-4 mt-6">
                        <div className="flex-grow border-t border-grey-300" />
                        <span className="px-4 pb-1">or</span>
                        <div className="flex-grow border-t border-grey-300" />
                    </div>
                    <div className={COL_FULL}>
                        <OutlinedButton
                            type="button"
                            className="w-full"
                            onClick={() => {navigate(register); onClickClose()}}
                        >
                            {t('general.register')}
                        </OutlinedButton>
                    </div>
                </div>
            </form>
        </Modal>
    )
}
