import React, { useContext } from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import UserIcon from 'src/components/icons/User'

const DEFAULT = 'mx-4 md:block hidden'
const NORMAL = 'text-white'
const INVERSE = 'text-dark-blue-600'

export default function NavLoggedIn({ profile }){
    const { state: { theme } } = useContext(ThemeContext);
    const { studentAccount, teacherAccount, editStudentAccount } = useLocalizedRoutes();

    const conditionalClasses = {
        [NORMAL]: theme !== 'inverse',
        [INVERSE]: theme === 'inverse'
    };

    function generateRoute() {
        if(profile.email_verified_at) {
            return profile.student_profile ? studentAccount : teacherAccount;
        } else {
            return profile.student_profile ? editStudentAccount : teacherAccount
        }
    }

    return (
        <Link className="flex justify-between items-center" to={generateRoute()}>
            <UserIcon/>
            <span className={classnames(DEFAULT, conditionalClasses)}>{profile.first_name}</span>
        </Link>
    )
}
