import React, {useState, useContext} from 'react'
import classnames from 'classnames'
import { supported } from 'i18n/locales'
import i18n from 'i18next'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import { Context as ThemeContext } from 'src/context/ThemeContext'
import DropDown from 'src/components/icons/Dropdown'

const DEFAULT = 'relative z-10 block h-8 w-8 overflow-hidden focus:outline-none flex items-center w-8 justify-between mx-4';
const NORMAL = 'focus:border-white text-white';
const INVERSE = 'focus:border-dark-blue-600 text-dark-blue-600';

export default function LanguageSelection(props) {
    const { route, param } = props;
    const [isOpen, setOpen] = useState(false);
    const { state: { theme } } = useContext(ThemeContext);
    const activeLanguage = i18n.languages[0];
    const { localisedRoute } = useLocalizedRoutes();
    
    const conditionalClasses = {
        [NORMAL]: theme !== 'inverse',
        [INVERSE]: theme === 'inverse'
    };

    function renderDropdown() {
        const otherLanguages = supported.filter(lang => lang !== activeLanguage)
        if(isOpen) {
            return (
              <div
                className="absolute right-0 mt-2 py-2 w-16 bg-white rounded-lg shadow-xl"
              >
                  {otherLanguages.map((locale, key) => (
                      <a
                        key={key}
                        href={localisedRoute(route, locale, param)}
                        hrefLang={locale}
                        rel="author"
                        className="block px-4 py-2 text-gray-800 hover:opacity-75"
                      >
                          {locale.toUpperCase()}
                      </a>
                  ))}
              </div>
            )
        }
    }


    return (
        <div className="relative">
            <button onClick={() => setOpen(!isOpen)} className={classnames(DEFAULT, conditionalClasses)}>
                {activeLanguage.toUpperCase()} <DropDown/>
            </button>
            { isOpen && <button onClick={() => setOpen(false)} tabIndex="-1" className="fixed inset-0 h-full w-full bg-black opacity-50 cursor-default"/>}
            {renderDropdown()}
        </div>
    )

}

